import { useComments } from '@afishauz/api/comments';
import type { Locale } from '@afishauz/api/config';
import {
  type EventScheduleCollection,
  useEventSchedules,
} from '@afishauz/api/event-schedules';
import { type MaterialCollection, useMaterials } from '@afishauz/api/materials';
import type { Section } from '@afishauz/api/sections';
import { getOptimizedDateString } from '@afishauz/lib/date';

export function useAnnouncements({
  locale,
  section,
  initialData,
}: {
  locale: Locale;
  section: Section;
  initialData?: EventScheduleCollection;
}) {
  return useEventSchedules({
    keyParams: {
      locale,
      'events.section': section['@id'],
      itemsPerPage: 8,
      'groups[]': ['material:permalink_canonical'],
    },
    initialData,
  });
}

export function useNewsAndReviews({
  section,
  locale,
  isOnHomepage,
  initialData,
}: {
  section?: Section;
  locale: Locale;
  isOnHomepage?: boolean;
  initialData?: MaterialCollection;
}) {
  return useMaterials({
    keyParams: {
      locale,
      'order[publishedAt]': 'DESC',
      isOnHomepage: isOnHomepage ? 1 : undefined,
      'groups[]': [
        'material:main_media_object',
        'media_object:read',
        'media_object:variants',
        'material:permalink_canonical',
      ],
      itemsPerPage: 15,
      'sections[]': section ? [section['@id']] : undefined,
      'type[]': ['NEWS', 'ARTICLE', 'PHOTOSET', 'VIDEO'],
      'status[]': ['PUBLISHED'],
    },
    nonKeyParams: {
      'publishedAt[before]': getOptimizedDateString(),
    },
    initialData,
  });
}

export function useEntityComments({ entity }: { entity: string }) {
  return useComments({
    keyParams: {
      entity,
      'groups[]': ['user:read'],
      'order[createdAt]': 'DESC',
      itemsPerPage: 4,
    },
  });
}
