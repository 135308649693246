import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { PropsWithChildren } from 'react';
import { Link } from './link';

export interface SectionTitleProps extends PropsWithChildren {
  href?: string;
}

export const SectionTitle = ({ href, children }: SectionTitleProps) => {
  return (
    <h2 className='text-xl font-medium pb-2 border-b border-gray-200 mb-4 md:text-2xl'>
      {href ? (
        <Link
          href={href}
          className='flex items-center gap-x-2 md:inline-flex group'
        >
          <span className='truncate'>{children}</span>
          <FontAwesomeIcon
            icon={faChevronRight}
            className='text-base md:text-lg group-hover:md:translate-x-1 md:transition-transform'
          />
        </Link>
      ) : (
        children
      )}
    </h2>
  );
};
