import type { GetCollectionOptions } from '../collection';
import {
  type SWROptions,
  type UseCollectionOptions,
  useCollection,
} from '../use-collection';
import type { GetCommentsParams } from './service';
import type { Comment } from './types';

export function useComments(
  options: UseCollectionOptions<Comment, Omit<GetCommentsParams, 'page'>> = {},
  requestOptions: GetCollectionOptions = {},
  swrConfig: SWROptions = {},
) {
  return useCollection<Comment>(
    'api/comments',
    options,
    requestOptions,
    swrConfig,
  );
}
